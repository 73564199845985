/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AnnotationsService } from './services/AnnotationsService';
import { AuthenticationService } from './services/AuthenticationService';
import { DocumentFoldersService } from './services/DocumentFoldersService';
import { DocumentsService } from './services/DocumentsService';
import { InvitationsService } from './services/InvitationsService';
import { LanguagesService } from './services/LanguagesService';
import { OntologiesService } from './services/OntologiesService';
import { ProjectsService } from './services/ProjectsService';
import { UserManagementService } from './services/UserManagementService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {

    public readonly annotations: AnnotationsService;
    public readonly authentication: AuthenticationService;
    public readonly documentFolders: DocumentFoldersService;
    public readonly documents: DocumentsService;
    public readonly invitations: InvitationsService;
    public readonly languages: LanguagesService;
    public readonly ontologies: OntologiesService;
    public readonly projects: ProjectsService;
    public readonly userManagement: UserManagementService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'http://localhost:8000',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.annotations = new AnnotationsService(this.request);
        this.authentication = new AuthenticationService(this.request);
        this.documentFolders = new DocumentFoldersService(this.request);
        this.documents = new DocumentsService(this.request);
        this.invitations = new InvitationsService(this.request);
        this.languages = new LanguagesService(this.request);
        this.ontologies = new OntologiesService(this.request);
        this.projects = new ProjectsService(this.request);
        this.userManagement = new UserManagementService(this.request);
    }
}

