import axios, { AxiosError } from "axios";

import { router } from "@/router";
import { AppClient } from "@/services";
import { useAuthenticationStore } from "@/stores/authentication";
import { useNotificationStore } from "@/stores/notification";

axios.defaults.xsrfCookieName = "xsrf_token";

interface AxiosErrorData {
  detail?: string | object;
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError<AxiosErrorData>) => {
    const authStore = useAuthenticationStore();
    const notificationStore = useNotificationStore();

    // network error
    if (error.code === "ERR_NETWORK") {
      notificationStore.error(
        "We cannot reach the server. Please check your internet connection."
      );
    }
    // timeout error
    else if (error.code === "ECONNABORTED") {
      notificationStore.error(
        `${error.code.toString().charAt(0).toLocaleUpperCase()} ${error.code
          .toString()
          .slice(1)}.`
      );
    }
    // authentication error
    else if (error.response?.status === 401) {
      await authStore.logout(false);
      notificationStore.warning(
        typeof error.response?.data?.detail === "string"
          ? error.response.data.detail
          : "Your session expired. Please log in again."
      );
      await router.push({ name: "Login" });
    }
    // authorization error
    else if (error.response?.status === 403) {
      await router.push({ name: "Home" });
      notificationStore.warning(
        "You are not authorized to access these resources."
      );
    }
    // user input error
    else if (error.response?.status === 422) {
      if (typeof error.response?.data?.detail === "string") {
        notificationStore.error(error.response.data.detail);
      } else {
        notificationStore.error(
          "Please check the data you have inserted. Have you filled all fields? Does the data comply with the provided rules?"
        );
      }
    }
    return Promise.reject(error);
  }
);

export const apiClient = new AppClient({
  BASE: process.env.VUE_APP_BASE_URL || "http://localhost:8000",
  WITH_CREDENTIALS: true,
});
