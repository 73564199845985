import { AxiosError } from "axios";
import { defineStore } from "pinia";

import { apiClient } from "@/plugins/api-client";
import { UserResponse } from "@/services";

import { useNotificationStore } from "./notification";
interface AuthenticationState {
  user: UserResponse | undefined;
}

export const useAuthenticationStore = defineStore({
  id: "authentication",
  state: (): AuthenticationState => ({
    user: undefined,
  }),
  getters: {
    isAuthenticated: (state) => state.user !== undefined,
  },
  actions: {
    async fetchMe() {
      const user = await apiClient.userManagement.getMe();
      this.user = user;
    },
    async login(username: string, password: string) {
      const user = await apiClient.authentication.login({
        email: username,
        password: password,
      });
      this.user = user;
    },
    async logout(showMessage = true) {
      const notificationStore = useNotificationStore();
      try {
        await apiClient.authentication.logout();
        this.user = undefined;
        if (showMessage) {
          notificationStore.addNotification({
            message: `Logged out successfully!`,
            type: "success",
          });
        }
      } catch (e: unknown) {
        const error = e as AxiosError<{ detail: string }>;
        let message = error.message;
        if (error.response?.data?.detail !== undefined) {
          message += ": " + error.response?.data?.detail;
        }
        notificationStore.addNotification({
          message: message,
          type: "error",
        });
      }
    },
  },
});
