/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateExperimentRequest } from '../models/CreateExperimentRequest';
import type { CreateExperimentResponse } from '../models/CreateExperimentResponse';
import type { CreateProjectRequest } from '../models/CreateProjectRequest';
import type { ExperimentResponse } from '../models/ExperimentResponse';
import type { ProjectExportResponse } from '../models/ProjectExportResponse';
import type { ProjectResponse } from '../models/ProjectResponse';
import type { UpdateProjectRequest } from '../models/UpdateProjectRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProjectsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Projects
     * @param excludeExperiments
     * @returns ProjectResponse Successful Response
     * @throws ApiError
     */
    public listProjects(
        excludeExperiments: boolean = false,
    ): CancelablePromise<Array<ProjectResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/projects',
            query: {
                'exclude_experiments': excludeExperiments,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Project
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public createProject(
        requestBody: CreateProjectRequest,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/projects',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project By Id
     * @param projectId
     * @returns ProjectResponse Successful Response
     * @throws ApiError
     */
    public getProjectById(
        projectId: string,
    ): CancelablePromise<ProjectResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Project
     * @param projectId
     * @returns void
     * @throws ApiError
     */
    public deleteProject(
        projectId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Project
     * @param projectId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public updateProject(
        projectId: string,
        requestBody: UpdateProjectRequest,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Experiment
     * @param projectId
     * @param requestBody
     * @returns CreateExperimentResponse Successful Response
     * @throws ApiError
     */
    public createExperiment(
        projectId: string,
        requestBody: CreateExperimentRequest,
    ): CancelablePromise<CreateExperimentResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/projects/{project_id}/experiments',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Experiment
     * Load experiments with a list of all Tasks appended.
     * @param experimentId
     * @param projectId
     * @returns ExperimentResponse Successful Response
     * @throws ApiError
     */
    public getExperiment(
        experimentId: string,
        projectId: string,
    ): CancelablePromise<ExperimentResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/projects/{project_id}/experiments/{experiment_id}',
            path: {
                'experiment_id': experimentId,
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Experiment
     * @param experimentId
     * @param projectId
     * @returns void
     * @throws ApiError
     */
    public deleteExperiment(
        experimentId: string,
        projectId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/projects/{project_id}/experiments/{experiment_id}',
            path: {
                'experiment_id': experimentId,
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Export Project Annotations
     * @param projectId
     * @param experimentIds
     * @returns ProjectExportResponse Successful Response
     * @throws ApiError
     */
    public exportProjectAnnotations(
        projectId: string,
        experimentIds: Array<string>,
    ): CancelablePromise<ProjectExportResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/projects/{project_id}/export',
            path: {
                'project_id': projectId,
            },
            query: {
                'experiment_ids': experimentIds,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
