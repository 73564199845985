/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DocumentParentFolder } from './DocumentParentFolder';

export type DocumentFolderResponse = {
    id: string;
    name: string;
    updated_at: string;
    updated_by?: string;
    parent_folder?: DocumentParentFolder;
    type?: DocumentFolderResponse.type;
};

export namespace DocumentFolderResponse {

    export enum type {
        FOLDER = 'folder',
    }


}
