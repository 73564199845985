/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginRequest } from '../models/LoginRequest';
import type { RegisterRequest } from '../models/RegisterRequest';
import type { UserResponse } from '../models/UserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthenticationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Csrf Token
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCsrfToken(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/auth/csrf',
        });
    }

    /**
     * Register User
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public registerUser(
        requestBody: RegisterRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Not a signup code.`,
                404: `Specified invitation does not exist or is invalid.`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Login
     * @param requestBody
     * @returns UserResponse Successful Response
     * @throws ApiError
     */
    public login(
        requestBody: LoginRequest,
    ): CancelablePromise<UserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Logout
     * @returns any Successful Response
     * @throws ApiError
     */
    public logout(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/logout',
        });
    }

}
