/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MappingProperty = {
    type: MappingProperty.type;
    title: string;
    uri: string;
};

export namespace MappingProperty {

    export enum type {
        SKOS_BROAD_MATCH = 'skos:broadMatch',
        SKOS_CLOSE_MATCH = 'skos:closeMatch',
        SKOS_EXACT_MATCH = 'skos:exactMatch',
        SKOS_NARROW_MATCH = 'skos:narrowMatch',
        SKOS_RELATED_MATCH = 'skos:relatedMatch',
    }


}
