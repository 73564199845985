/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DocumentParentFolder } from './DocumentParentFolder';

export type DocumentFileResponse = {
    id: string;
    title: string;
    updated_at: string;
    updated_by?: string;
    parent_folder?: DocumentParentFolder;
    type?: DocumentFileResponse.type;
};

export namespace DocumentFileResponse {

    export enum type {
        FILE = 'file',
    }


}
