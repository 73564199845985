import VueRouter, { RouteConfig } from "vue-router";

import { checkIsAuthorized } from "@/security/authorization";
import { useAuthenticationStore } from "@/stores/authentication";
import { usePreferencesStore } from "@/stores/preferences";
import UserProfileView from "@/views/UserProfileView.vue";

import LoginView from "../views/LoginView.vue";
import PasswordRecovery from "../views/PasswordRecoveryView.vue";
import PasswordReset from "../views/PasswordResetView.vue";
import annotationRoutes from "./annotations";
import documentRoutes from "./documents";
import ontologyRoutes from "./ontologies";
import projectRoutes from "./projects";
import userRoutes from "./users";

declare module "vue-router" {
  interface RouteMeta {
    roles?: Set<string>;
  }
}

const routes: Array<RouteConfig> = [
  ...ontologyRoutes,
  ...documentRoutes,
  ...projectRoutes,
  ...userRoutes,
  ...annotationRoutes,
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/LandingPageView.vue"),
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("@/views/SignUpView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/profile",
    name: "Profile",
    component: UserProfileView,
  },
  {
    path: "/forgot-password",
    name: "PasswordRecovery",
    component: PasswordRecovery,
  },
  {
    path: "/reset-password",
    name: "PasswordReset",
    component: PasswordReset,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const publicRoutes = new Set([
  "Login",
  "PasswordRecovery",
  "PasswordReset",
  "SignUp",
]);

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthenticationStore();
  const preferencesStore = usePreferencesStore();
  // check to name is defined and can be evaluated
  // this is a safe fail if a route is not defined correctly in development
  if (!to.name) {
    console.error(`Please define the route name: ${to.fullPath}`);
    return;
  }
  // check if user is authenticated if to route is not public else redirect to Login
  if (!publicRoutes.has(to.name) && !authStore.isAuthenticated) {
    try {
      await authStore.fetchMe();
    } catch {
      /* tslint:disable:no-empty */
    }
  }
  // load preferences from local storage on every authenticated route
  if (authStore.isAuthenticated) {
    await preferencesStore.initializeFromLocalStorage();
  }
  // check if user is authorized to access to route else redirect to Landing Page
  if (to.meta?.roles && !(await checkIsAuthorized(to.meta.roles))) {
    return next({ name: "Home", replace: true });
  }
  return next();
});

export { router };
