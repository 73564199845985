/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserResponse = {
    name: string;
    email: string;
    role: UserResponse.role;
};

export namespace UserResponse {

    export enum role {
        RESEARCHER = 'Researcher',
        WORKSPACE_ADMIN = 'Workspace Admin',
        ANNOTATOR = 'Annotator',
    }


}

