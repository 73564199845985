/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MappingPropertyRequest = {
    concept_mapping_property_type: MappingPropertyRequest.concept_mapping_property_type;
    uri: string;
    title: string;
};

export namespace MappingPropertyRequest {

    export enum concept_mapping_property_type {
        SKOS_BROAD_MATCH = 'skos:broadMatch',
        SKOS_CLOSE_MATCH = 'skos:closeMatch',
        SKOS_EXACT_MATCH = 'skos:exactMatch',
        SKOS_NARROW_MATCH = 'skos:narrowMatch',
        SKOS_RELATED_MATCH = 'skos:relatedMatch',
    }


}
