/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Body_upload_documents = {
  file: Blob;
  text_column_key: string;
  title_column_key?: string;
  delimiter: Body_upload_documents.delimiter;
};

export namespace Body_upload_documents {
  export enum delimiter {
    COMMA = ",",
    SEMICOLON = ";",
    PIPE = "|",
    TAB = "\t",
  }
}
