import { roles } from "@/security/roles";

export default [
  {
    path: "/send-invite",
    name: "SendInvite",
    component: () => import("@/views/UserManagementInviteView.vue"),
    meta: {
      roles: new Set([roles.WORKSPACE_ADMIN]),
    },
  },
  {
    path: "/users",
    name: "UserManagement",
    component: () => import("@/views/UserManagementView.vue"),
    meta: {
      roles: new Set([roles.WORKSPACE_ADMIN]),
    },
  },
];
