/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConceptLabelRequest = {
    name: string;
    language: string;
    concept_label_type: ConceptLabelRequest.concept_label_type;
};

export namespace ConceptLabelRequest {

    export enum concept_label_type {
        SKOS_ALT_LABEL = 'skos:altLabel',
        SKOS_HIDDEN_LABEL = 'skos:hiddenLabel',
        SKOS_NOTATION = 'skos:notation',
        SKOS_PREF_LABEL = 'skos:prefLabel',
        SKOS_DEFINITION = 'skos:definition',
        SKOS_CHANGE_NOTE = 'skos:changeNote',
        SKOS_EDITORIAL_NOTE = 'skos:editorialNote',
        SKOS_EXAMPLE = 'skos:example',
        SKOS_HISTORY_NOTE = 'skos:historyNote',
        SKOS_NOTE = 'skos:note',
        SKOS_SCOPE_NOTE = 'skos:scopeNote',
    }


}

