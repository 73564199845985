/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnnotationAssignmentsResponse } from '../models/AnnotationAssignmentsResponse';
import type { AnnotationAssignmentTaskResponse } from '../models/AnnotationAssignmentTaskResponse';
import type { AnnotationResponse } from '../models/AnnotationResponse';
import type { AnnotationStatisticsResponse } from '../models/AnnotationStatisticsResponse';
import type { CreateAnnotationRequest } from '../models/CreateAnnotationRequest';
import type { ExperimentAssignment } from '../models/ExperimentAssignment';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AnnotationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Annotation Statistics
     * @param experimentIds
     * @returns AnnotationStatisticsResponse Successful Response
     * @throws ApiError
     */
    public getAnnotationStatistics(
        experimentIds: Array<string>,
    ): CancelablePromise<AnnotationStatisticsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/annotations/projects/statistics',
            query: {
                'experiment_ids': experimentIds,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Annotation Assignments
     * @param projectId
     * @param experimentId
     * @returns AnnotationAssignmentsResponse Successful Response
     * @throws ApiError
     */
    public getAnnotationAssignments(
        projectId: string,
        experimentId: string,
    ): CancelablePromise<AnnotationAssignmentsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/annotations/projects/{project_id}/experiments/{experiment_id}',
            path: {
                'project_id': projectId,
                'experiment_id': experimentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Experiment Assignments
     * @param projectId
     * @returns ExperimentAssignment Successful Response
     * @throws ApiError
     */
    public getExperimentAssignments(
        projectId?: string,
    ): CancelablePromise<Array<ExperimentAssignment>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/annotations',
            query: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Annotation Assignment
     * @param taskId
     * @param projectId
     * @param experimentId
     * @returns AnnotationAssignmentTaskResponse Successful Response
     * @throws ApiError
     */
    public getAnnotationAssignment(
        taskId: string,
        projectId: string,
        experimentId: string,
    ): CancelablePromise<AnnotationAssignmentTaskResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/annotations/projects/{project_id}/experiments/{experiment_id}/tasks/{task_id}',
            path: {
                'task_id': taskId,
                'project_id': projectId,
                'experiment_id': experimentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Complete Task
     * @param taskId
     * @param projectId
     * @param experimentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public completeTask(
        taskId: string,
        projectId: string,
        experimentId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/annotations/projects/{project_id}/experiments/{experiment_id}/tasks/{task_id}/complete',
            path: {
                'task_id': taskId,
                'project_id': projectId,
                'experiment_id': experimentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Skip Task
     * @param taskId
     * @param projectId
     * @param experimentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public skipTask(
        taskId: string,
        projectId: string,
        experimentId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/annotations/projects/{project_id}/experiments/{experiment_id}/tasks/{task_id}/skip',
            path: {
                'task_id': taskId,
                'project_id': projectId,
                'experiment_id': experimentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Annotation
     * @param taskId
     * @param projectId
     * @param experimentId
     * @param requestBody
     * @returns AnnotationResponse Successful Response
     * @throws ApiError
     */
    public createAnnotation(
        taskId: string,
        projectId: string,
        experimentId: string,
        requestBody: CreateAnnotationRequest,
    ): CancelablePromise<AnnotationResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/annotations/projects/{project_id}/experiments/{experiment_id}/tasks/{task_id}/annotations',
            path: {
                'task_id': taskId,
                'project_id': projectId,
                'experiment_id': experimentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Annotation
     * @param annotationId
     * @param taskId
     * @param projectId
     * @param experimentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteAnnotation(
        annotationId: string,
        taskId: string,
        projectId: string,
        experimentId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/annotations/projects/{project_id}/experiments/{experiment_id}/tasks/{task_id}/annotations/{annotation_id}',
            path: {
                'annotation_id': annotationId,
                'task_id': taskId,
                'project_id': projectId,
                'experiment_id': experimentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
