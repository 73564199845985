/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDocumentFolderRequest } from '../models/CreateDocumentFolderRequest';
import type { DocumentFileResponse } from '../models/DocumentFileResponse';
import type { DocumentFolderAttributesResponse } from '../models/DocumentFolderAttributesResponse';
import type { DocumentFolderResponse } from '../models/DocumentFolderResponse';
import type { EditDocumentFolderRequest } from '../models/EditDocumentFolderRequest';
import type { StructureFolder } from '../models/StructureFolder';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DocumentFoldersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Folder Structure
     * Return the complete treeview of the folder structure, from root to deepest nested folder.
     * Difference to the list folders is that the strcutre is delivered without documents.
     * @returns StructureFolder Successful Response
     * @throws ApiError
     */
    public listFolderStructure(): CancelablePromise<Array<StructureFolder>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/folders/structure',
        });
    }

    /**
     * List Document Folders
     * @param folderId
     * @returns any Successful Response
     * @throws ApiError
     */
    public listDocumentFolders(
        folderId?: string,
    ): CancelablePromise<Array<(DocumentFolderResponse | DocumentFileResponse)>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/folders',
            query: {
                'folder_id': folderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Document Folder
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public createDocumentFolder(
        requestBody: CreateDocumentFolderRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/folders',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Document Folder
     * @param folderId
     * @returns DocumentFolderResponse Successful Response
     * @throws ApiError
     */
    public getDocumentFolder(
        folderId: string,
    ): CancelablePromise<DocumentFolderResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/folders/{folder_id}',
            path: {
                'folder_id': folderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Document Folder
     * Delete a folder by id.
     * @param folderId
     * @returns void
     * @throws ApiError
     */
    public deleteDocumentFolder(
        folderId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/folders/{folder_id}',
            path: {
                'folder_id': folderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Rename Document Folder
     * @param folderId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public renameDocumentFolder(
        folderId: string,
        requestBody: EditDocumentFolderRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/folders/{folder_id}',
            path: {
                'folder_id': folderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Document Folder Attributes
     * @param folderId
     * @returns DocumentFolderAttributesResponse Successful Response
     * @throws ApiError
     */
    public getDocumentFolderAttributes(
        folderId: string,
    ): CancelablePromise<DocumentFolderAttributesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/folders/{folder_id}/attributes',
            path: {
                'folder_id': folderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
