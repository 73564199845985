import { RawLocation } from "vue-router";
import { useRouter } from "vue-router/composables";

import { useAuthenticationStore } from "@/stores/authentication";

export const checkIsAuthorized = async (
  allowedRoles: Set<string> | undefined
): Promise<boolean> => {
  const authStore = useAuthenticationStore();
  return Boolean(
    allowedRoles &&
      authStore.user?.role &&
      allowedRoles.has(authStore.user.role)
  );
};

export const checkIsRouteAuthorized = (route: RawLocation): boolean => {
  const router = useRouter();
  const authStore = useAuthenticationStore();
  const resolvedRoute = router.resolve(route);
  return Boolean(
    authStore.user?.role &&
      resolvedRoute.route.meta?.roles?.has(authStore.user.role)
  );
};
