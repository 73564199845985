/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvitationRequest } from '../models/InvitationRequest';
import type { InvitationResponse } from '../models/InvitationResponse';
import type { ResendInvitationRequest } from '../models/ResendInvitationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InvitationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Invitations
     * @returns InvitationResponse Successful Response
     * @throws ApiError
     */
    public listInvitations(): CancelablePromise<Array<InvitationResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/invitations',
        });
    }

    /**
     * Resend Invitation
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public resendInvitation(
        requestBody: ResendInvitationRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/invitations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Invitation
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public sendInvitation(
        requestBody: InvitationRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/invitations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
