/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_upload_documents } from '../models/Body_upload_documents';
import type { DocumentResponse } from '../models/DocumentResponse';
import type { UpdateDocumentFolderRequest } from '../models/UpdateDocumentFolderRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DocumentsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Document By Id
     * @param documentId
     * @returns DocumentResponse Successful Response
     * @throws ApiError
     */
    public getDocumentById(
        documentId: string,
    ): CancelablePromise<DocumentResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/documents/{document_id}',
            path: {
                'document_id': documentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Document
     * Delete a document by id.
     * @param documentId
     * @returns void
     * @throws ApiError
     */
    public deleteDocument(
        documentId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/documents/{document_id}',
            path: {
                'document_id': documentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload Documents
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public uploadDocuments(
        formData: Body_upload_documents,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/documents',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Move Documents
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public moveDocuments(
        requestBody: UpdateDocumentFolderRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/documents',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
