import { roles } from "@/security/roles";

export default [
  {
    path: "/projects",
    name: "ProjectList",
    component: () => import("@/views/ProjectListView.vue"),
  },
  {
    path: "/projects/new",
    name: "ProjectCreate",
    component: () => import("@/views/ProjectCreateView.vue"),
    meta: {
      roles: new Set([roles.WORKSPACE_ADMIN, roles.RESEARCHER]),
    },
  },
  {
    path: "/projects/:projectId",
    name: "ProjectContainer",
    component: () => import("@/views/ProjectContainerView.vue"),
    children: [
      {
        path: "settings",
        name: "ProjectSettings",
        component: () => import("@/views/ProjectSettingsView.vue"),
        meta: {
          roles: new Set([roles.WORKSPACE_ADMIN, roles.RESEARCHER]),
        },
      },
      {
        path: "export",
        name: "ProjectAnnotationsExport",
        component: () => import("@/views/ProjectExportView.vue"),
        meta: {
          roles: new Set([roles.WORKSPACE_ADMIN, roles.RESEARCHER]),
        },
      },
      {
        path: "statistics",
        name: "ProjectAnnotationsStatistics",
        component: () => import("@/views/ProjectAnnotationStatisticsView.vue"),
        meta: {
          roles: new Set([roles.WORKSPACE_ADMIN, roles.RESEARCHER]),
        },
      },
      {
        path: "guidelines",
        name: "ProjectAnnotationsGuidelines",
        component: () => import("@/views/ProjectAnnotationGuidelinesView.vue"),
        meta: {
          roles: new Set([roles.WORKSPACE_ADMIN, roles.RESEARCHER]),
        },
      },
      {
        path: "experiments",
        name: "ExperimentContainer",
        component: () => import("@/views/ProjectExperimentView.vue"),
      },
      {
        path: "experiments/new",
        name: "ExperimentCreate",
        component: () => import("@/views/ProjectExperimentCreateView.vue"),
        meta: {
          roles: new Set([roles.WORKSPACE_ADMIN, roles.RESEARCHER]),
        },
      },
      {
        path: "experiments/:experimentId",
        name: "ExperimentDetail",
        component: () => import("@/views/ProjectExperimentDetailView.vue"),
      },
    ],
  },
];
