/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfirmEmailRequest } from '../models/ConfirmEmailRequest';
import type { DeleteMeRequest } from '../models/DeleteMeRequest';
import type { ForgotPasswordRequest } from '../models/ForgotPasswordRequest';
import type { LoginRequest } from '../models/LoginRequest';
import type { RegisterRequest } from '../models/RegisterRequest';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { UpdateEmailRequest } from '../models/UpdateEmailRequest';
import type { UpdatePasswordRequest } from '../models/UpdatePasswordRequest';
import type { UpdateUserManagmentRequest } from '../models/UpdateUserManagmentRequest';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';
import type { UserManagementResponse } from '../models/UserManagementResponse';
import type { UserResponse } from '../models/UserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserManagementService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Csrf Token
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCsrfToken(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/auth/csrf',
        });
    }

    /**
     * Register User
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public registerUser(
        requestBody: RegisterRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Not a signup code.`,
                404: `Specified invitation does not exist or is invalid.`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Login
     * @param requestBody
     * @returns UserResponse Successful Response
     * @throws ApiError
     */
    public login(
        requestBody: LoginRequest,
    ): CancelablePromise<UserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Logout
     * @returns any Successful Response
     * @throws ApiError
     */
    public logout(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/logout',
        });
    }

    /**
     * Change Pw
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public changePw(
        requestBody: UpdatePasswordRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/users/me/change-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Request Email Change
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public requestEmailChange(
        requestBody: UpdateEmailRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/users/me/change-email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Confirm Email Change
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public confirmEmailChange(
        requestBody: ConfirmEmailRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/users/confirm-email-change',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Me
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteMe(
        requestBody: DeleteMeRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/users/me/delete',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Me
     * @returns UserResponse Successful Response
     * @throws ApiError
     */
    public getMe(): CancelablePromise<UserResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/me',
        });
    }

    /**
     * Update Profile
     * @param requestBody
     * @returns UserResponse Successful Response
     * @throws ApiError
     */
    public updateProfile(
        requestBody: UpdateUserRequest,
    ): CancelablePromise<UserResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/users/me',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Forgot Password
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public forgotPassword(
        requestBody: ForgotPasswordRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/users/forgot-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reset Password
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public resetPassword(
        requestBody: ResetPasswordRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/users/reset-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Users
     * @returns UserManagementResponse Successful Response
     * @throws ApiError
     */
    public getUsers(): CancelablePromise<Array<UserManagementResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/',
        });
    }

    /**
     * Delete User
     * @param userId
     * @returns void
     * @throws ApiError
     */
    public deleteUser(
        userId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/users/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit User
     * @param userId
     * @param requestBody
     * @returns UserManagementResponse Successful Response
     * @throws ApiError
     */
    public editUser(
        userId: string,
        requestBody: UpdateUserManagmentRequest,
    ): CancelablePromise<UserManagementResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/users/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
