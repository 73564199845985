import { defineStore } from "pinia";
import Vue, { computed } from "vue";

import { apiClient } from "@/plugins/api-client";

import { useAuthenticationStore } from "./authentication";

interface NavigationItem {
  name: string;
  count: number;
  color?: string;
  description?: string;
}

interface NavigationItemInput {
  id: string;
  name: string;
  color?: string;
  description?: string;
}

interface FrequentItems {
  [key: string]: NavigationItem;
}

export const usePreferencesStore = defineStore("preferences", () => {
  const authenticationStore = useAuthenticationStore();

  // frequent projects
  const frequentProjects: FrequentItems = {};
  const countProject = ({ id, name, description }: NavigationItemInput) => {
    Vue.set(frequentProjects, id, {
      name,
      description,
      count: frequentProjects[id] ? frequentProjects[id].count + 1 : 1,
    });
    storeToLocalStorage();
  };
  const deleteProject = (id: string): void => {
    Vue.delete(frequentProjects, id);
    storeToLocalStorage();
  };

  // frequent ontologies
  const frequentOntologies: FrequentItems = {};
  const countOntology = ({
    id,
    name,
    color,
    description,
  }: NavigationItemInput) => {
    Vue.set(frequentOntologies, id, {
      name,
      color,
      description,
      count: frequentProjects[id] ? frequentProjects[id].count + 1 : 1,
    });
    storeToLocalStorage();
  };
  const deleteOntology = (id: string): void => {
    Vue.delete(frequentOntologies, id);
    storeToLocalStorage();
  };

  // local storage interactions
  const localStorageKey = computed((): string => {
    if (authenticationStore.user?.name) {
      return `${authenticationStore.user.name}/preferences`;
    } else {
      throw Error("Preferences cannot be stored. Please log in.");
    }
  });
  const storeToLocalStorage = () => {
    localStorage.setItem(
      localStorageKey.value,
      JSON.stringify({
        frequentProjects,
        frequentOntologies,
      })
    );
  };
  const initializeFromLocalStorage = async () => {
    const preferences = localStorage.getItem(localStorageKey.value);

    const projects = await apiClient.projects.listProjects(true);
    const ontologies = await apiClient.ontologies.listOntologies();

    if (preferences) {
      const parsedPreferences = JSON.parse(preferences);
      Object.entries(parsedPreferences.frequentProjects).forEach(
        ([projectId, project]) => {
          if (projects.map((project) => project.id).includes(projectId)) {
            Vue.set(frequentProjects, projectId, project);
          } else {
            Vue.delete(frequentProjects, projectId);
          }
        }
      );
      Object.entries(parsedPreferences.frequentOntologies).forEach(
        ([ontologyId, ontology]) => {
          if (ontologies.map((ontology) => ontology.id).includes(ontologyId)) {
            Vue.set(frequentOntologies, ontologyId, ontology);
          } else {
            Vue.delete(frequentOntologies, ontologyId);
          }
        }
      );
      storeToLocalStorage();
    }
  };
  const resetPreferences = () => {
    Object.keys(frequentOntologies).forEach((key) =>
      Vue.delete(frequentOntologies, key)
    );
    Object.keys(frequentProjects).forEach((key) =>
      Vue.delete(frequentProjects, key)
    );
    localStorage.removeItem(localStorageKey.value);
  };

  return {
    frequentProjects,
    countProject,
    deleteProject,
    frequentOntologies,
    countOntology,
    deleteOntology,
    initializeFromLocalStorage,
    resetPreferences,
  };
});
