import Vue, { getCurrentInstance } from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

const useVuetify = () => {
  const instance = getCurrentInstance();
  if (!instance) {
    throw new Error("useVuetify should called in setup().");
  }
  return instance.proxy.$vuetify;
};

export { useVuetify };

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        background: "#FFFFFF",
        surface: "#FFFFFF",
        primary: "#fe583e",
        secondary: "#21232d",
        success: "#52E052",
        warning: "#FFC94D",
        error: "#A40505",
        info: "#fe583e",
        "on-background": "#21232d",
        "on-surface": "#21232d",
        "on-primary": "#ffffff",
        "on-secondary": "#ffffff",
        "on-success": "#21232d",
        "on-warning": "#21232d",
        "on-error": "#ffffff",
        "on-info": "#ffffff",
      },
      dark: {
        background: "#21232d",
        surface: "#21232d",
        primary: "#fe583e",
        secondary: "#21232d",
        success: "#52E052",
        warning: "#FFC94D",
        error: "#A40505",
        info: "#fe583e",
        "on-background": "#ffffff",
        "on-surface": "#ffffff",
        "on-primary": "#ffffff",
        "on-secondary": "#ffffff",
        "on-success": "#21232d",
        "on-warning": "#21232d",
        "on-error": "#ffffff",
        "on-info": "#ffffff",
      },
    },
  },
});
