/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InvitationRequest = {
    email: string;
    role: InvitationRequest.role;
};

export namespace InvitationRequest {

    export enum role {
        RESEARCHER = 'Researcher',
        WORKSPACE_ADMIN = 'Workspace Admin',
        ANNOTATOR = 'Annotator',
    }


}

