import { defineStore } from "pinia";
export interface Notification {
  // defining the possible types of notifications, each mapped to a color
  type: "info" | "warning" | "success" | "error";
  message: string;
}

interface NotificationState {
  notifications: Array<Notification>; //stack
  notification: Notification | undefined; //individual notification
  isNotificationVisible: boolean; //whether notification is visible
  timeout: number; //how long notification shown (in miliseconds)
  waittime: number; //pause between notifications (in miliseconds)
  processing: boolean; // if processing true stack is being processed
}

export const useNotificationStore = defineStore({
  id: "notification",
  state: (): NotificationState => ({
    notifications: [],
    notification: undefined,
    isNotificationVisible: false,
    timeout: 3500,
    waittime: 500,
    processing: false, //as default no stack yet
  }),
  getters: {},
  actions: {
    deleteNotification() {
      /*function to delete the notifications that stayed their designated wait time already
      also works through the built up stack to show elements after each other following
      the fifo process  */

      this.notifications = this.notifications.slice(1);
      this.isNotificationVisible = false;
      // if still elements left, stack is deleted one by one
      if (this.notifications.length > 0) {
        // showing next element
        this.notification = this.notifications[0];
        this.isNotificationVisible = true;
        setTimeout(this.deleteNotification, this.waittime + this.timeout);
      } else {
        // stack is empty, nothing left to process
        this.processing = false;
      }
    },

    addNotification(notification: Notification) {
      /*function to add the notification to the stack. When stack is empty also invokes
      delete function. When stack full only adds to stack  */

      this.notifications.push(notification);
      this.notification = this.notifications[0];
      this.isNotificationVisible = true;
      // if stack not filled up yet
      if (this.processing == false) {
        this.processing = true;
        setTimeout(this.deleteNotification, this.waittime + this.timeout);
      }
    },

    info(message: string) {
      this.addNotification({
        type: "info",
        message: message,
      });
    },

    warning(message: string) {
      this.addNotification({
        type: "warning",
        message: message,
      });
    },

    success(message: string) {
      this.addNotification({
        type: "success",
        message: message,
      });
    },

    error(message: string) {
      this.addNotification({
        type: "error",
        message: message,
      });
    },
  },
});
