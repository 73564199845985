import { defineStore } from "pinia";

import { apiClient } from "@/plugins/api-client";
import type { LanguageResponse } from "@/services";

interface LanguageState {
  languages: LanguageResponse[];
}

export const useLanguageStore = defineStore({
  id: "language",
  state: (): LanguageState => ({
    languages: [],
  }),
  getters: {},
  actions: {
    async fetchLanguages() {
      if (this.languages.length < 1) {
        this.languages = await apiClient.languages.listLanguages();
      }
    },
  },
});
