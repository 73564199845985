import "@mdi/font/css/materialdesignicons.css";
import "roboto-fontface/css/roboto/roboto-fontface.css";

import { createPinia, PiniaVuePlugin } from "pinia";
import Vue from "vue";
import VueRouter from "vue-router";

import App from "./App.vue";
import $dayjs from "./plugins/dayjs";
import ECharts from "./plugins/e-charts";
import vuetify from "./plugins/vuetify";
import { router } from "./router";

Vue.config.productionTip = false;

Vue.component("v-chart", ECharts);
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(VueRouter);

new Vue({
  vuetify,
  pinia,
  router,
  render: (h) => h(App),
  provide: {
    $dayjs: $dayjs,
  },
}).$mount("#app");
