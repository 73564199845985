import { roles } from "@/security/roles";

import ListView from "../views/OntologyListView.vue";

export default [
  {
    path: "/ontologies",
    name: "OntologyList",
    component: ListView,
  },
  {
    path: "/ontologies/new",
    name: "OntologyCreate",
    component: () => import("@/views/OntologyCreateView.vue"),
    meta: {
      roles: new Set([roles.WORKSPACE_ADMIN, roles.RESEARCHER]),
    },
  },
  {
    path: "/ontologies/import",
    name: "OntologyImport",
    component: () => import("@/views/OntologyImportView.vue"),
    meta: {
      roles: new Set([roles.WORKSPACE_ADMIN, roles.RESEARCHER]),
    },
  },
  {
    path: "/ontologies/:ontologyId",
    name: "OntologyContainer",
    component: () => import("@/views/OntologyContainerView.vue"),
    children: [
      {
        path: "settings",
        name: "OntologySettings",
        component: () => import("@/views/OntologySettingsView.vue"),
        meta: {
          roles: new Set([roles.WORKSPACE_ADMIN, roles.RESEARCHER]),
        },
      },
      {
        path: "export",
        name: "OntologyExport",
        component: () => import("@/views/OntologyExportView.vue"),
        meta: {
          roles: new Set([roles.WORKSPACE_ADMIN, roles.RESEARCHER]),
        },
      },
      {
        path: "concepts",
        name: "ConceptContainer",
        component: () => import("@/views/OntologyConceptContainerView.vue"),
        children: [
          {
            path: "new",
            name: "ConceptCreate",
            component: () => import("@/views/OntologyConceptCreateView.vue"),
            meta: {
              roles: new Set([roles.WORKSPACE_ADMIN, roles.RESEARCHER]),
            },
          },
          {
            path: ":conceptId",
            name: "ConceptDetails",
            component: () => import("@/views/OntologyConceptDetailsView.vue"),
          },
        ],
      },
      {
        path: "visualization",
        name: "OntologyVisualization",
        component: () => import("@/views/OntologyVisualizationView.vue"),
      },
    ],
  },
];
