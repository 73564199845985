import "echarts";

import { registerTheme } from "echarts";
import ECharts from "vue-echarts";

import dark from "./themes/darkmode.json";
import light from "./themes/lightmode.json";

registerTheme("darkMode", dark);
registerTheme("lightMode", light);
// export default und deswegen importierbar ohne {} sonst spezifizieren welche 'version' genau
export default ECharts;
