import { roles } from "@/security/roles";

export default [
  {
    path: "/documents/new",
    name: "DocumentCreate",
    component: () => import("@/views/DocumentCreateView.vue"),
    meta: {
      roles: new Set([roles.WORKSPACE_ADMIN, roles.RESEARCHER]),
    },
    props: true,
  },
  {
    path: "/documents/:folderId?",
    name: "DocumentList",
    component: () => import("@/views/DocumentListView.vue"),
  },
  {
    path: "/documents/preview/:documentId",
    name: "DocumentDetails",
    component: () => import("@/views/DocumentDetails.vue"),
  },
];
