/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_import_ontology } from '../models/Body_import_ontology';
import type { ConceptResponse } from '../models/ConceptResponse';
import type { CreateConceptRequest } from '../models/CreateConceptRequest';
import type { CreateOntologyRequest } from '../models/CreateOntologyRequest';
import type { OntologyExportResponseModel } from '../models/OntologyExportResponseModel';
import type { OntologyResponse } from '../models/OntologyResponse';
import type { SingleConceptResponse } from '../models/SingleConceptResponse';
import type { UpdateConceptBroaderConceptRequest } from '../models/UpdateConceptBroaderConceptRequest';
import type { UpdateConceptPriorityRequest } from '../models/UpdateConceptPriorityRequest';
import type { UpdateConceptRequest } from '../models/UpdateConceptRequest';
import type { UpdateOntologyRequest } from '../models/UpdateOntologyRequest';
import type { VisualizationResponse } from '../models/VisualizationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OntologiesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Ontologies
     * @returns OntologyResponse Successful Response
     * @throws ApiError
     */
    public listOntologies(): CancelablePromise<Array<OntologyResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/ontologies',
        });
    }

    /**
     * Create Ontology
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public createOntology(
        requestBody: CreateOntologyRequest,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/ontologies',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Ontology By Id
     * @param ontologyId
     * @returns OntologyResponse Successful Response
     * @throws ApiError
     */
    public getOntologyById(
        ontologyId: string,
    ): CancelablePromise<OntologyResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/ontologies/{ontology_id}',
            path: {
                'ontology_id': ontologyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Ontology
     * @param ontologyId
     * @returns void
     * @throws ApiError
     */
    public deleteOntology(
        ontologyId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/ontologies/{ontology_id}',
            path: {
                'ontology_id': ontologyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Ontology
     * @param ontologyId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public updateOntology(
        ontologyId: string,
        requestBody: UpdateOntologyRequest,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/ontologies/{ontology_id}',
            path: {
                'ontology_id': ontologyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Ontology Visualization
     * @param ontologyId
     * @returns VisualizationResponse Successful Response
     * @throws ApiError
     */
    public getOntologyVisualization(
        ontologyId: string,
    ): CancelablePromise<Array<VisualizationResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/ontologies/{ontology_id}/visualization',
            path: {
                'ontology_id': ontologyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Export Ontology
     * @param ontologyId
     * @param exportFormat
     * @returns OntologyExportResponseModel Successful Response
     * @throws ApiError
     */
    public exportOntology(
        ontologyId: string,
        exportFormat: 'json' = 'json',
    ): CancelablePromise<OntologyExportResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/ontologies/{ontology_id}/export',
            path: {
                'ontology_id': ontologyId,
            },
            query: {
                'export_format': exportFormat,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Import Ontology
     * @param formData
     * @param importFormat
     * @returns string Successful Response
     * @throws ApiError
     */
    public importOntology(
        formData: Body_import_ontology,
        importFormat: 'json' = 'json',
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/ontologies/import',
            query: {
                'import_format': importFormat,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Scrape Title
     * @param url
     * @returns string Successful Response
     * @throws ApiError
     */
    public scrapeTitle(
        url: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/ontologies/mapping-properties/auto-suggest-title',
            query: {
                'url': url,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Concepts
     * List all concept for a given ontology id.
     * @param ontologyId
     * @returns ConceptResponse Successful Response
     * @throws ApiError
     */
    public listConcepts(
        ontologyId: string,
    ): CancelablePromise<Array<ConceptResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/ontologies/{ontology_id}/concepts',
            path: {
                'ontology_id': ontologyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Concept
     * Create a new concept.
     * @param ontologyId
     * @param requestBody
     * @returns ConceptResponse Successful Response
     * @throws ApiError
     */
    public createConcept(
        ontologyId: string,
        requestBody: CreateConceptRequest,
    ): CancelablePromise<ConceptResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/ontologies/{ontology_id}/concepts',
            path: {
                'ontology_id': ontologyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Concept By Id
     * Get a single concept by concept id.
     * @param ontologyId
     * @param conceptId
     * @returns SingleConceptResponse Successful Response
     * @throws ApiError
     */
    public getConceptById(
        ontologyId: string,
        conceptId: string,
    ): CancelablePromise<SingleConceptResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/ontologies/{ontology_id}/concepts/{concept_id}',
            path: {
                'ontology_id': ontologyId,
                'concept_id': conceptId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Concept
     * Delete a single concept.
     * @param ontologyId
     * @param conceptId
     * @returns void
     * @throws ApiError
     */
    public deleteConcept(
        ontologyId: string,
        conceptId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/ontologies/{ontology_id}/concepts/{concept_id}',
            path: {
                'ontology_id': ontologyId,
                'concept_id': conceptId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Concept
     * Update a single concept.
     * @param ontologyId
     * @param conceptId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public updateConcept(
        ontologyId: string,
        conceptId: string,
        requestBody: UpdateConceptRequest,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/ontologies/{ontology_id}/concepts/{concept_id}',
            path: {
                'ontology_id': ontologyId,
                'concept_id': conceptId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Concept Priorities
     * Update all concept priorities for a given ontology id.
     * @param ontologyId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public updateConceptPriorities(
        ontologyId: string,
        requestBody: Array<UpdateConceptPriorityRequest>,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/ontologies/{ontology_id}/concepts/priorities',
            path: {
                'ontology_id': ontologyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Broader Relation
     * Update all concept relations for a given ontology id.
     * @param ontologyId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public updateBroaderRelation(
        ontologyId: string,
        requestBody: UpdateConceptBroaderConceptRequest,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/ontologies/{ontology_id}/concepts/relations',
            path: {
                'ontology_id': ontologyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
